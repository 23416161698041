import styled from "styled-components";
import { MEDIA_QUERIES } from "@justomx/avocado-ui";

import orangeDesktop from "@/images/orange-desktop.svg";
import organgeMobile from "@/images/orange-mobile.svg";
import { ReactComponent as sendIcon } from "@/images/send-icon.svg";

const StyledContainer = styled.section`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "data"
    "image";
  background-image: url(${organgeMobile});
  background-position: right bottom;
  background-clip: padding-box;
  background-repeat: no-repeat;

  @media ${MEDIA_QUERIES.lg} {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 40%;
    grid-template-areas: "data image";
    background-image: url(${orangeDesktop});
    background-position: calc(100% + 20px) calc(100% + 20px);
  }
`;

const StyledDataContainer = styled.div`
  grid-area: data;
  margin-left: 28px;
  margin-right: 28px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  @media ${MEDIA_QUERIES.lg} {
    margin-left: 42px;
    margin-bottom: 42px;
    align-items: flex-start;
  }
`;

const StyledImageContainer = styled.div`
  grid-area: image;
`;

const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  @media ${MEDIA_QUERIES.lg} {
    text-align: left;
  }
`;

const StyledLoginInfo = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.48px;
  color: #000000;
  margin-top: 156px;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px;
  a {
    font-weight: bold;
    color: #024638;
    text-decoration: none;
  }
  @media ${MEDIA_QUERIES.lg} {
    margin-top: 0;
    border-radius: none;
    background-color: none;
    padding: 0;
  }
`;

const StyledBrandImage = styled.img`
  display: block;
  margin-bottom: 55px;
  svg {
    width: 186px;
    height: auto;
  }
  @media ${MEDIA_QUERIES.lg} {
    margin-bottom: 38px;
  }
`;

const StyledSendIcon = styled(sendIcon)`
  display: block;
`;

const StyledForm = styled.form``;

const StyledLabel = styled.label`
  width: 100%;
`;

const StyledInputLabel = styled.p`
  color: #333333;
  font-size: 11.5px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.61px;
  line-height: normal;
  margin-bottom: 8px;
`;

const StyledInputContainer = styled.div`
  background-color: #fafafa;
  border-radius: 6px;
  border: solid 1px #1c4e32;
  display: flex;
  height: 36px;
  overflow: hidden;
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: none;
  color: #154734;
  flex: 1;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 100%;
  letter-spacing: normal;
  line-height: normal;
  padding: 10px 12px;
`;

const StyledInputButton = styled.button`
  align-items: center;
  background-color: #154734;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 66px;

  &:disabled {
    background-color: #bdbdbd;
  }
`;

export {
  StyledBrandImage,
  StyledContainer,
  StyledDataContainer,
  StyledDescription,
  StyledForm,
  StyledImageContainer,
  StyledInput,
  StyledInputButton,
  StyledInputContainer,
  StyledInputLabel,
  StyledLabel,
  StyledLoginInfo,
  StyledSendIcon,
};
