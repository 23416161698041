import Cookies from "js-cookie";

function getUserTraits() {
  let traits = {};

  const userData = Cookies.get("user_data");

  if (userData) {
    const [firstName, lastName, email] = userData.split("|");
    traits = { firstName, lastName, email };
  }

  return traits;
}

export { getUserTraits };
