import { useContext } from "react";
import { ZipCodeModalContext } from "./ZipCodeModalContext";
import { actions } from "./ZipCodeModalReducer";

function useZipCodeModalContext() {
  const { state, dispatch } = useContext(ZipCodeModalContext);

  const { show } = state;

  const toggle = () => {
    dispatch({ type: actions.SET_CLOSABLE, data: !show });
  };

  const setShow = (data) => {
    dispatch({ type: actions.SET_SHOW, data });
  };

  const setAddressLabel = (data) => {
    dispatch({ type: actions.SET_ADDRESS_LABEL, data });
  };

  return {
    ...state,
    toggle,
    setShow,
    setAddressLabel,
  };
}

export { useZipCodeModalContext };
