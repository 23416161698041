import { gql } from "@apollo/client";

const GET_CMS_FOOTER = gql`
  query {
    CMSfooter {
      data {
        attributes {
          footer_sections {
            ... on ComponentTermsConditionsTermsConditions {
              footer_row {
                id
                name
                redirect
              }
            }
          }
          payments_methods {
            icon {
              data {
                attributes {
                  url
                  alternativeText
                }
              }
            }
          }
          footer_logo_redirect
          footer_name
          footer_logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

const GET_CMS_HEADER = gql`
  query {
    CMSheader {
      data {
        attributes {
          header_logo_redirect
          header_name
          header_logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`;

export { GET_CMS_FOOTER, GET_CMS_HEADER };
