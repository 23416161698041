import React from "react";
import { i18next } from "@/translations";
import PropTypes from "prop-types";
import {
  Header,
  ProductDescription,
  ProductImage,
  ProductInfo,
  Products,
  ProductDetails,
  ProductVariant,
  ProductQuantity,
  HeaderProduct,
  HeaderQuantity,
} from "./ModalBody.styles";

function ModalBody({ lines }) {
  return (
    <Products>
      <Header>
        <HeaderProduct>
          {i18next.t("modalBody.product", "Product")}
        </HeaderProduct>
        <HeaderQuantity>
          {i18next.t("modalBody.quantity_to_delete", "Quantity to delete")}
        </HeaderQuantity>
      </Header>

      {lines.map(({ node }) => {
        const { variant, quantity } = node;
        const { product, name } = variant;
        return (
          <ProductInfo key={variant.id}>
            <ProductImage src={product.thumbnailUrl} loading="lazy" />
            <ProductDescription>
              <h1>{product.name}</h1>
              <ProductDetails>
                <ProductVariant>{name}</ProductVariant>
                <ProductQuantity>{quantity}</ProductQuantity>
              </ProductDetails>
            </ProductDescription>
          </ProductInfo>
        );
      })}
    </Products>
  );
}

ModalBody.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  lines: PropTypes.array,
};

export { ModalBody };
