import styled from "styled-components";
import { Button } from "@/components/Button";

import { mediaQueries } from "@/utils/css";

const StyledTitle = styled.p`
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0px;
  margin-bottom: 0px;
`;

const Warning = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.2px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
`;

const Buttons = styled.div`
  width: 100%;
  display: block;
  text-align: center;
`;

const Action = styled(Button)`
  cursor: pointer;
  height: 42px;
  width: 180px;

  :first-child {
    margin-right: 10px;
  }

  @media ${mediaQueries.mobileL} {
    width: 100%;
    margin: 0px;
    :first-child {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
`;

const StyledContainer = styled.section`
  padding: 0 2rem 2rem;
  height: calc(100vh - 127px);
  overflow-y: scroll;
`;

export { Action, StyledTitle, Buttons, Warning, StyledContainer };
