import { gql } from "@apollo/client";

const STORES_QUERY = gql`
  query stores($postalCode: String) {
    stores(first: 1, filter: { postalCode: $postalCode }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export { STORES_QUERY };
