import { i18next } from "@/translations";

const EMAIL_REQUIRED = i18next.t(
  "noCoverageModal.emailRequired",
  "Email required"
);

const WE_HAVENT_REACHED = i18next.t(
  "noCoverageModal.weHaventReached",
  "Looks like we haven't reached that area yet"
);

const SHARE_US_AN_EMAIL = i18next.t(
  "noCoverageModal.shareUsAnEmail",
  "Share us an email to let you know when there is coverage in"
);

const WRITE_YOUR_EMAIL = i18next.t(
  "noCoverageModal.writeYourEmail",
  "Write your email"
);

const INVALID_FORMAT = i18next.t("noCoverageModal.consfirm", "Invalid format");

const ERROR_MESSAGE = i18next.t(
  "noCoverageModal.errorMessage",
  "An error occurred"
);

const SAVED_EMAIL = i18next.t("noCoverageModal.savedEmail", "Saved email");

const WE_WILL_NOTIFY = i18next.t(
  "noCoverageModal.weWillNotify",
  "We will notify you when there is coverage in"
);

const GO_BACK = i18next.t("noCoverageModal.goBack", "Go back");

const CONFIRM = i18next.t("noCoverageModal.confirm", "Confirm");

export {
  EMAIL_REQUIRED,
  WE_HAVENT_REACHED,
  SHARE_US_AN_EMAIL,
  WRITE_YOUR_EMAIL,
  INVALID_FORMAT,
  ERROR_MESSAGE,
  SAVED_EMAIL,
  WE_WILL_NOTIFY,
  GO_BACK,
  CONFIRM,
};
