import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FullModalBody } from "@/components/FullModal";
import { useCountryConfig } from "@/hooks/useCountryConfig";
import { DistrictSearch } from "@/shared/DistrictSearch";
import { NoCoverageModal } from "@/shared/NoCoverageModal";

import * as TR from "./DistrictModal.translations";
import * as Styled from "./DistrictModal.styles";

function DistrictModal({ onChangeZipCode, show, onClose, closable }) {
  const [zipCode, setZipCode] = useState("");
  const [district, setDistrict] = useState("");
  const [noCoverage, setNocoverage] = useState(false);
  const [showNoCoverageModal, setShowNoCoverageModal] = useState(false);
  const { getMaskProperty } = useCountryConfig();
  const { regex } = getMaskProperty("zipCode");

  const onSearch = (postalCode) => {
    setZipCode(postalCode);
  };

  const isValidZipCode = useMemo(() => {
    return regex.exec(zipCode);
  }, [zipCode]);

  const onConfirm = () => {
    onChangeZipCode(zipCode);
  };

  const onChangeDistrict = (value, district) => {
    setNocoverage(value);
    setDistrict(district);
  };

  const onNoCoverage = () => {
    onClose();
    setShowNoCoverageModal(true);
  };

  const onCloseNoCoverage = () => {
    setShowNoCoverageModal(false);
    onChangeDistrict(false, "");
  };

  const onSucces = () => {
    onClose();
    setShowNoCoverageModal(false);
    onChangeDistrict(false, "");
  };

  if (showNoCoverageModal) {
    return (
      <NoCoverageModal
        closable
        district={district}
        show={showNoCoverageModal}
        onClose={onCloseNoCoverage}
        onSucces={onSucces}
      />
    );
  }

  return (
    <FullModalBody show={show} closable={closable} onClose={onClose}>
      <Styled.Container>
        <Styled.Title variant="body1">{TR.WHERE_WILL_WE_DELIVER}</Styled.Title>
        <DistrictSearch
          onChangeZipCode={onSearch}
          onChangeDistrict={onChangeDistrict}
        />
        {noCoverage ? (
          <Styled.NoCoverageButton
            full
            variant="terciary"
            onClick={onNoCoverage}
          >
            <Styled.Icon name="NotificationOn" xWidth="20px" xHeight="20px" />
            {TR.NOTIFY}
          </Styled.NoCoverageButton>
        ) : (
          <>
            <Styled.LoginInfo variant="body1" forwardedAs="div">
              {TR.ALREADY_HAVE_AN_ACCOUNT}{" "}
              <a href="/account/login/">{TR.LOG_IN}</a>
            </Styled.LoginInfo>
            <Styled.Button
              full
              onClick={onConfirm}
              variant="secondary"
              disabled={!isValidZipCode}
            >
              {TR.CONFIRM}
            </Styled.Button>
          </>
        )}
      </Styled.Container>
    </FullModalBody>
  );
}

DistrictModal.propTypes = {
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeZipCode: PropTypes.func,
  show: PropTypes.bool,
};

DistrictModal.defaultProps = {
  closable: false,
  show: false,
  onChangeZipCode: () => {},
  onClose: () => {},
};

export { DistrictModal };
