import * as graphqlClient from "@/utils/graphql-client";
import { getCookie } from "@/hooks/useCookies";
import {
  CHECKOUT_POSTAL_CODE_UPDATE,
  UNAVAILABLE_CHECKOUT_LINES,
} from "./NoStockModal.queries";

async function cartUpdateTracking(checkout, lines, token) {
  try {
    const trackingData = {
      cart_id: token,
      discount: checkout.checkout.discountAmount.amount,
      coupon: checkout.checkout.voucher,
      revenue: checkout.checkout.subtotalPrice.gross.amount,
      value:
        checkout.checkout.subtotalPrice.gross.amount -
        checkout.checkout.discountAmount.amount +
        checkout.checkout.shippingPrice.gross.amount,
      shipping: checkout.checkout.shippingPrice.gross.amount,
      currency: checkout.checkout.totalPrice.gross.currency,
      tax: checkout.checkout.totalPrice.net.amount,
      warehouse: checkout.checkout.warehouse,
    };
    analytics.track("Cart Updated", {
      ...trackingData,
      products: lines.map((line) => {
        line = line.node;
        return {
          product_id: graphqlClient.fromGlobalId(line.variant.product.id).id,
          sku: line.variant.sku,
          price: line.variant.product.pricing.priceRange.stop.gross.amount,
          quantity: line.quantity,
          category: graphqlClient.fromGlobalId(line.variant.product.category.id)
            .id,
          url: line.variant.product.url,
        };
      }),
    });
  } catch (errors) {
    return [];
  }
}

async function updatePostalCodeCheckout(newZipCode) {
  const checkoutCookie = getCookie("checkout");

  if (!checkoutCookie) return;

  const checkoutId = graphqlClient.toGlobalId("Checkout", checkoutCookie);

  const variables = { checkoutId, postalCode: newZipCode };

  await graphqlClient.fetch(
    CHECKOUT_POSTAL_CODE_UPDATE,
    variables,
    "checkoutPostalCodeUpdate"
  );
}

async function getUnavailableCheckoutLines(postalCode) {
  try {
    const variables = { postalCode };

    const { unavailableCheckoutLines } = await graphqlClient.fetch(
      UNAVAILABLE_CHECKOUT_LINES,
      variables,
      "unavailableCheckoutLines"
    );

    return unavailableCheckoutLines.edges;
  } catch (errors) {
    return [];
  }
}

export {
  cartUpdateTracking,
  getUnavailableCheckoutLines,
  updatePostalCodeCheckout,
};
