import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useNotificationContext } from "@/contexts/NotificationContext";

import { FullModalBody } from "@/components/FullModal";
import { getInitialValues, getSchema } from "./NoCoverageModal.utils";
import { MUTATION_CREATE_SUBSCRIPTION } from "./NoCoverageModal.queries";

import * as TR from "./NoCoverageModal.translations";
import * as Styled from "./NoCoverageModal.styles";

function NoCoverageModal({ district, onSuccess, show, onClose }) {
  const { showError, showSuccess } = useNotificationContext();

  const onSubmit = (data) => {
    createSubscription({
      variables: {
        input: {
          email: data.email,
          zone: district,
        },
      },
    });
  };

  const [createSubscription, { loading }] = useMutation(
    MUTATION_CREATE_SUBSCRIPTION,
    {
      onCompleted: ({ createSubscription }) => {
        const { errors } = createSubscription;

        if (errors.length) {
          const [{ message }] = errors;
          const error = {
            title: TR.ERROR_MESSAGE,
            message: message,
          };
          showError(error);
          return;
        }

        const notify = `${TR.WE_WILL_NOTIFY} ${district}`;

        onSuccess();
        showSuccess({
          title: TR.SAVED_EMAIL,
          message: notify,
        });
      },
      onError: (error) => {
        const [e] = error.graphQLErrors;
        const errorObj = {
          title: TR.ERROR_MESSAGE,
          message: e.message,
        };
        onClose();
        showError(errorObj);
      },
    }
  );

  return (
    <FullModalBody show={show} closable onClose={onClose}>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={getSchema()}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Styled.Container>
            <Styled.Title variant="body1">{TR.WE_HAVENT_REACHED}</Styled.Title>
            <Styled.Description variant="body2">
              {TR.SHARE_US_AN_EMAIL}{" "}
              <Styled.Caption variant="caption">{district}</Styled.Caption>
            </Styled.Description>
            <form onSubmit={handleSubmit}>
              <Styled.Label isActive={!!values.email}>
                {TR.WRITE_YOUR_EMAIL}
              </Styled.Label>
              <Styled.Input
                name="email"
                placeholder={TR.WRITE_YOUR_EMAIL}
                value={values.email}
                onChange={handleChange}
                state={errors.email && "error"}
                helpText={errors.email}
              />
              <Styled.ButtonsContainer>
                <Styled.ButtonBack
                  disabled={loading}
                  variant="terciary"
                  onClick={onClose}
                >
                  {TR.GO_BACK}
                </Styled.ButtonBack>
                <Styled.ButtonConfirm
                  disabled={loading || errors.email || !values.email}
                  variant="secondary"
                >
                  {TR.CONFIRM}
                </Styled.ButtonConfirm>
              </Styled.ButtonsContainer>
            </form>
          </Styled.Container>
        )}
      </Formik>
    </FullModalBody>
  );
}

NoCoverageModal.propTypes = {
  district: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  show: PropTypes.bool,
};

NoCoverageModal.defaultProps = {
  show: false,
  onSuccess: () => {},
  onClose: () => {},
};

export { NoCoverageModal };
