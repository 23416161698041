import React from "react";
import PropTypes from "prop-types";
import { useCountryConfig } from "@justomx/common/dist/hooks/useCountryConfig";

import * as Styled from "./Header.styles";

function Header({ as, logo }) {
  const {
    countryConfig: { country },
  } = useCountryConfig();
  const isPE = country === "PE";

  const {
    header_name: headerName,
    header_logo_redirect: headerLogoRedirect,
    header_logo: { url: urlLogo },
  } = logo;

  return (
    <Styled.Container as={as}>
      <Styled.LogoContainer>
        <Styled.Link href={headerLogoRedirect || "/"}>
          {isPE ? (
            <Styled.LogoFresh src={urlLogo} alt={headerName} />
          ) : (
            <Styled.SiteLogo src={urlLogo} alt={headerName} />
          )}
        </Styled.Link>
      </Styled.LogoContainer>
    </Styled.Container>
  );
}

Header.propTypes = {
  as: PropTypes.string,
  logo: PropTypes.shape({
    header_name: PropTypes.string,
    header_logo_redirect: PropTypes.string,
    header_logo: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export { Header };
