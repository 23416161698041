import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import segmentPlugin from "@analytics/segment";
import googleTagManager from "@analytics/google-tag-manager";

/* initialize analytics and load plugins */
const analytics = Analytics({
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.NEXT_PUBLIC_GA_TRACKING_ID],
    }),
    segmentPlugin({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    }),
    googleTagManager({
      containerId: process.env.NEXT_PUBLIC_GTM_ID,
    }),
  ],
});

/* export analytics for usage through the app */
export default analytics;
