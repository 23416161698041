import React, { useReducer, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { trackZipCodeUpdate } from "@/utils/analytics";

import { ZipCodeModal } from "@/shared/ZipCodeModal";
import { DistrictModal } from "@/shared/DistrictModal";
import { AddressModal } from "@/shared/AddressModal";
import { NoStockModal } from "@/shared/NoStockModal";
import { useCookies, setCookie } from "@/hooks/useCookies";
import { useCountryConfig } from "@/hooks/useCountryConfig";
import { updateSiteCookies } from "@/utils/checkout";

import {
  ZipCodeModalReducer,
  actions,
  initialState,
} from "./ZipCodeModalReducer";
import { STORES_QUERY } from "./ZipCodeModalContext.queries";
import { ZipCodeModalContext } from "./ZipCodeModalContext";

function ZipCodeModalProvider({ children }) {
  const [state, dispatch] = useReducer(ZipCodeModalReducer, initialState);
  const { newZipCode, newAddressId, addressLabel } = state;
  const { isValidZipCode, zipCode, isAuthenticated } = useCookies();
  const { getProperty } = useCountryConfig();
  const country = getProperty("country");
  const isPeru = country === "PE";

  const noStockModalRef = useRef();

  const [fetchStores] = useLazyQuery(STORES_QUERY, {
    onCompleted: async ({ stores }) => {
      if (stores === null) {
        analytics.track("No Coverage Selected", {
          postal_code_new: newZipCode,
        });

        location.replace(`/no-coverage/${newZipCode}/`);

        return;
      }

      const [{ node: newStore }] = stores.edges;

      const urlParams = new URLSearchParams(location.search);
      const destination = urlParams.get("destination");

      const label = isPeru ? addressLabel : newZipCode;
      updateSiteCookies(newZipCode, newAddressId);
      setCookie("address_label", label);

      await noStockModalRef.current.updatePostalCodeCheckout(newZipCode);

      trackZipCodeUpdate(newZipCode, zipCode, newStore.name);

      // If route comes with a destination query, redirect to destination
      if (destination) {
        location.replace(destination);
        return;
      }

      // Redirect to home if you change the PostalCode in /no-coverage/ route
      const noCoverageRegex = RegExp(/no-coverage/, "g");

      if (noCoverageRegex.test(location.pathname)) {
        location.replace("/");
        return;
      }

      // just refresh on given case the set of postal code was successful.
      location.reload();
    },
  });

  useEffect(() => {
    if (newZipCode) {
      fetchStores({
        variables: { postalCode: newZipCode },
      });
    }
  }, [newZipCode]);

  const onClose = () => {
    dispatch({ type: actions.SET_SHOW, data: false });
  };

  const updateZipCode = (newZipCode, newAddressId) => {
    dispatch({
      type: actions.SET_NEW_ZIP_CODE,
      data: {
        newAddressId,
        newZipCode,
      },
    });
  };

  const onConfirmNoStockChange = (newZipCode, addressId) => {
    updateZipCode(newZipCode, addressId);
  };

  const changeZipCode = async (newZipCode, addressId) => {
    const hasUnavailableLines =
      await noStockModalRef.current.hasUnavailableLines(newZipCode);

    if (hasUnavailableLines) {
      noStockModalRef.current.open();
    } else {
      updateZipCode(newZipCode, addressId);
    }
  };

  if (isAuthenticated) {
    return (
      <ZipCodeModalContext.Provider value={{ state, dispatch }}>
        {children}
        <AddressModal
          show={state.show}
          closable={isValidZipCode}
          onSubmit={changeZipCode}
          onClose={onClose}
        />
        <NoStockModal
          ref={noStockModalRef}
          onConfirm={onConfirmNoStockChange}
        />
      </ZipCodeModalContext.Provider>
    );
  }

  if (isPeru && !isAuthenticated) {
    return (
      <ZipCodeModalContext.Provider value={{ state, dispatch }}>
        {children}
        <DistrictModal
          show={state.show}
          closable={isValidZipCode}
          onChangeZipCode={changeZipCode}
          onClose={onClose}
        />
        <NoStockModal
          ref={noStockModalRef}
          onConfirm={onConfirmNoStockChange}
        />
      </ZipCodeModalContext.Provider>
    );
  }

  return (
    <ZipCodeModalContext.Provider value={{ state, dispatch }}>
      {children}
      <ZipCodeModal
        show={state.show}
        closable={isValidZipCode}
        onSubmit={changeZipCode}
        onClose={onClose}
      />
      <NoStockModal ref={noStockModalRef} onConfirm={onConfirmNoStockChange} />
    </ZipCodeModalContext.Provider>
  );
}

ZipCodeModalProvider.propTypes = {
  children: PropTypes.any,
};

export { ZipCodeModalProvider };
