import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { FullModalBody } from "@/components/FullModal";
import { useCountryConfig } from "@/hooks/useCountryConfig";
import justoLogo from "@/images/logo.svg";

import {
  StyledBrandImage,
  StyledContainer,
  StyledDataContainer,
  StyledDescription,
  StyledForm,
  StyledImageContainer,
  StyledInput,
  StyledInputButton,
  StyledInputContainer,
  StyledInputLabel,
  StyledLabel,
  StyledLoginInfo,
  StyledSendIcon,
} from "./ZipCodeModal.styles";

import { i18next } from "@/translations";

function ZipCodeModal({ onSubmit, show, onClose, closable }) {
  const [zipCode, setZipCode] = useState("");
  const { getMaskProperty } = useCountryConfig();
  const { mask, regex } = getMaskProperty("zipCode");

  const onZipCodeChange = ({ formattedValue }) => {
    setZipCode(formattedValue);
  };

  const isValidZipCode = useMemo(() => {
    return regex.exec(zipCode);
  }, [zipCode]);

  const onSubmitForm = (event) => {
    event.preventDefault();
    onSubmit(zipCode);
  };

  return (
    <FullModalBody show={show} onClose={onClose} closable={closable}>
      <StyledContainer>
        <StyledDataContainer>
          <StyledBrandImage alt="Logo" src={justoLogo} />
          <StyledDescription>
            {i18next.t(
              "zipCodeModal.enter_postal_code",
              "Enter postal code, to know the availability in your area"
            )}
          </StyledDescription>
          <StyledForm onSubmit={onSubmitForm}>
            <StyledLabel>
              <StyledInputLabel>
                {i18next.t("zipCodeModal.postal_code", "Postal Code")}
              </StyledInputLabel>
              <StyledInputContainer>
                <NumberFormat
                  customInput={StyledInput}
                  placeholder={i18next.t(
                    "zipCodeModal.postal_code",
                    "Postal Code"
                  )}
                  format={mask}
                  onValueChange={onZipCodeChange}
                  name="zip_code"
                />
                <StyledInputButton disabled={!isValidZipCode}>
                  <StyledSendIcon />
                </StyledInputButton>
              </StyledInputContainer>
            </StyledLabel>
          </StyledForm>
          <StyledLoginInfo>
            {i18next.t(
              "zipCodeModal.already_have_an_account",
              "Already have an account?"
            )}{" "}
            <a href="/account/login/">
              {i18next.t("zipCodeModal.log_in", "Log in")}
            </a>
          </StyledLoginInfo>
        </StyledDataContainer>
        <StyledImageContainer />
      </StyledContainer>
    </FullModalBody>
  );
}

ZipCodeModal.propTypes = {
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  show: PropTypes.bool,
};

ZipCodeModal.defaultProps = {
  closable: false,
  show: false,
  onSubmit: () => {},
  onClose: () => {},
};

export { ZipCodeModal };
