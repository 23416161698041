const actions = {
  SET_SHOW: "SET_SHOW",
  SET_NEW_ZIP_CODE: "SET_NEW_ZIP_CODE",
  SET_ADDRESS_LABEL: "SET_ADDRESS_LABEL",
};

const initialState = {
  show: false,
  newZipCode: "",
  newAddressId: "",
  addressLabel: "",
};

function ZipCodeModalReducer(state, { data, type }) {
  switch (type) {
    case actions.SET_SHOW: {
      return {
        ...state,
        show: data,
      };
    }
    case actions.SET_NEW_ZIP_CODE: {
      const { newZipCode, newAddressId } = data;

      return {
        ...state,
        newZipCode,
        newAddressId,
      };
    }
    case actions.SET_ADDRESS_LABEL: {
      const addressLabel = data;

      return {
        ...state,
        addressLabel,
      };
    }
    default:
      return state;
  }
}

export { actions, initialState, ZipCodeModalReducer };
