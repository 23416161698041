import { gql } from "@apollo/client";

const MUTATION_CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($input: SubscriptionInput!) {
    createSubscription(input: $input) {
      subscription {
        id
        email
        postalCode
        zone
      }
      errors {
        field
        message
      }
    }
  }
`;

export { MUTATION_CREATE_SUBSCRIPTION };
