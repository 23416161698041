import React from "react";
import PropTypes from "prop-types";
import * as Styled from "./DistrictName.styles";
import * as TR from "./DistrictName.translations";

function DistrictName({ onClickDistrict, node }) {
  const { name, fullCoverage } = node;

  return (
    <Styled.District
      onClick={() => onClickDistrict(node)}
      variant="body1"
      forwardedAs="a"
    >
      {name}{" "}
      {!fullCoverage && (
        <Styled.Partial>
          <Styled.Icon name="Info" xWidth="30px" xHeight="20px" />
          <Styled.Span forwardedAs="span">{TR.DISTRICT_COVERAGE}</Styled.Span>
          <Styled.Icon name="Arrow" xHeight="18px" xWidth="18px" />
        </Styled.Partial>
      )}
    </Styled.District>
  );
}

DistrictName.propTypes = {
  onClickDistrict: PropTypes.func,
  node: PropTypes.shape({
    name: PropTypes.string,
    fullCoverage: PropTypes.bool,
  }),
};

export { DistrictName };
