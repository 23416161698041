import { ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { logout } from "@/utils/session";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "include",
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const [{ message }] = graphQLErrors;
    const MESSAGE_ERROR_EXPIRED = "signature is invalid";
    const hasTokenExpired = message
      .toLowerCase()
      .includes(MESSAGE_ERROR_EXPIRED);

    if (hasTokenExpired) {
      logout();
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("jwt_token");

  if (!token) {
    return {
      headers: headers,
    };
  }

  return {
    headers: {
      ...headers,
      authorization: `JWT ${token}`,
    },
  };
});

const cmsClient = new ApolloClient({
  uri: process.env.REACT_APP_FLY_CMS_URL,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const coreClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export { coreClient, cmsClient };
