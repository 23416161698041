import { i18next } from "@/translations";

const YOUR_DISTRIT = i18next.t(
  "districtSearch.yourDistrict",
  "Write your district"
);

const TRY_ANOTHER_DSTRICT = i18next.t(
  "districtSearch.tryAnotherDistrict",
  "We haven't reached your address yet, try another district"
);

const NEIGHBORHOODS_COVERAGE = i18next.t(
  "districtSearch.neighborhoodsCoverage",
  "neighborhoods with coverage in "
);

export { YOUR_DISTRIT, TRY_ANOTHER_DSTRICT, NEIGHBORHOODS_COVERAGE };
