import React from "react";
import PropTypes from "prop-types";
import { CustomButton } from "./Button.styles";

function Button({ children, outline, block, ...props }) {
  return (
    <CustomButton outline={outline} block={block} {...props}>
      {children}
    </CustomButton>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  outline: PropTypes.bool,
  block: PropTypes.bool,
};

Button.defaultProps = {
  outline: false,
  block: false,
};

Button.displayName = "Button";

export { Button };
