import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Suggestions } from "./components/Suggestions";
import { useDebounce } from "@/hooks/useDebounce";

import * as TR from "./DistrictSearch.translations";
import * as Styled from "./DistrictSearch.styles";
import { useZipCodeModalContext } from "@/contexts/ZipCodeModalContext/useZipCodeModalContext";

function DistrictSearch({ onChangeZipCode, onChangeDistrict }) {
  const { setAddressLabel } = useZipCodeModalContext();
  const [district, setDistrict] = useState("");
  const [fullCoverage, setFullCoverage] = useState(true);
  const [zones, setZones] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [noCoverage, setNocoverage] = useState(false);
  const debouncedDistrict = useDebounce(district, 300);
  const autocompleteRef = useRef();

  const onChangeSearch = ({ target }) => {
    setFullCoverage(true);
    setDistrict(target.value);
  };

  const districtNoCoverage = (value) => {
    setNocoverage(value);
    onChangeDistrict(value, debouncedDistrict);
  };

  const onClickDistrict = (data) => {
    const { name, zipCode, fullCoverage, zones = [] } = data;
    setDistrict(name);
    setFullCoverage(fullCoverage);
    setZones(zones);
    onChangeZipCode(zipCode);
    setShowSuggestions(false);
    setAddressLabel(name);
  };

  const checkIfClickedOutside = ({ target }) => {
    const showAutocomplete =
      showSuggestions &&
      autocompleteRef.current &&
      !autocompleteRef.current.contains(target);

    if (showAutocomplete) {
      setShowSuggestions(false);
    }
  };

  const onAutocompleteEnter = ({ key }) => {
    if (key === "Enter") {
      setShowSuggestions(false);
    }
  };

  const onClearInput = () => {
    setDistrict("");
    setFullCoverage(true);
    onChangeZipCode("");
    setShowSuggestions(false);
    districtNoCoverage(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSuggestions]);

  return (
    <>
      <Styled.PartialCoverage show={!fullCoverage}>
        <Styled.TitlePartialCoverage>
          {TR.NEIGHBORHOODS_COVERAGE} {district}
        </Styled.TitlePartialCoverage>
        <Styled.ZonesPartialCoverage>
          {zones.join(", ")}
        </Styled.ZonesPartialCoverage>
      </Styled.PartialCoverage>
      <Styled.Label show={!!district} error={noCoverage} variant="caption">
        {TR.YOUR_DISTRIT}
      </Styled.Label>
      <Styled.Container ref={autocompleteRef}>
        <Styled.Input
          onClick={() => setShowSuggestions(!showSuggestions)}
          value={district}
          onChange={onChangeSearch}
          onKeyPress={onAutocompleteEnter}
          placeholder={TR.YOUR_DISTRIT}
          state={noCoverage && "error"}
        />
        {district && (
          <Styled.IconContainer onClick={onClearInput}>
            <Styled.CloseIcon name="Close" xHeight="15px" xWidth="15px" />
          </Styled.IconContainer>
        )}
        {showSuggestions && (
          <Suggestions
            district={debouncedDistrict}
            onClickDistrict={onClickDistrict}
            districtNoCoverage={districtNoCoverage}
            noCoverage={noCoverage}
          />
        )}
        {noCoverage && (
          <Styled.NoCoverage variant="body2">
            {TR.TRY_ANOTHER_DSTRICT}
          </Styled.NoCoverage>
        )}
      </Styled.Container>
    </>
  );
}

DistrictSearch.propTypes = {
  onChangeZipCode: PropTypes.func,
  onChangeDistrict: PropTypes.func,
};

DistrictSearch.defaultProps = {
  onChangeZipCode: () => {},
  onChangeDistrict: () => {},
};

export { DistrictSearch };
