import styled from "styled-components";
import { Typography, Icon as AvocadoIcon } from "@justomx/avocado-ui";

const District = styled(Typography)`
  color: ${({ theme }) => theme.colors.grayscale.gray1};
  cursor: pointer;
  margin: 0px;
  padding: 10px;
  display: flex;
  align-items: center;
  :hover {
    text-decoration: none;
    background: ${({ theme }) => theme.colors.grayscale.gray5};
  }
`;

const Partial = styled(Typography)`
  color: ${({ theme }) => theme.colors.status.warning};
  font-size: 13px;
  display: inline-flex;
  margin: 0;
`;

const Span = styled(Typography)`
  font-size: 12px;
  margin-right: 4px;
`;

const Icon = styled(AvocadoIcon).attrs(({ theme }) => ({
  fill: theme.colors.status.warning,
}))`
  transform: ${({ name }) =>
    name === "Arrow" ? "rotate(-90deg) scale(.8)" : "scale(.8)"};
`;

export { District, Partial, Span, Icon };
