import { useContext } from "react";
import { NotificationContext } from "./NotificationContext";

import {
  ERROR_OPTIONS,
  SUCCESS_OPTIONS,
  TALON_OPTIONS,
} from "./NotificationContext.constants";

function useNotificationContext() {
  const { setNotification } = useContext(NotificationContext);

  const showError = ({ title, message, ...otherProps }) => {
    setNotification({
      type: "error",
      platform: "local",
      title,
      message,
      options: {
        ...ERROR_OPTIONS,
        ...otherProps,
      },
    });
  };

  const showSuccess = ({ image, title, message, ...otherProps }) => {
    setNotification({
      type: "success",
      platform: "local",
      image,
      title,
      message,
      options: {
        ...SUCCESS_OPTIONS,
        ...otherProps,
      },
    });
  };

  const showCustomAlert = ({ type, title, message, ...otherProps }) => {
    setNotification({
      type,
      platform: "custom",
      title,
      message,
      options: {
        ...TALON_OPTIONS,
        ...otherProps,
      },
    });
  };

  return { showError, showSuccess, showCustomAlert };
}

export { useNotificationContext };
