import styled from "styled-components";
import {
  Typography,
  InputText,
  MEDIA_QUERIES,
  Button as AvocadoButton,
} from "@justomx/avocado-ui";

const Container = styled.section`
  padding: 20px;
  padding-top: 0px;
  width: 100%;
`;

const Title = styled(Typography)`
  font-weight: 700;
  margin: 0px;
  margin-bottom: 24px;
`;

const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.grayscale.gray3};
  font-weight: 400;
  margin: 0px;
  margin-bottom: 18px;
`;

const Caption = styled(Typography)`
  color: ${({ theme }) => theme.colors.green50};
  font-size: 14px;
  font-weight: 400;
`;

const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.grayscale.gray3};
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
`;

const LoginInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 400;
  margin: 0px;
  margin-top: 38px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.green50};
    font-weight: 500;
    text-decoration: none;
  }
`;

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-top: 37px;

  @media ${MEDIA_QUERIES.md} {
    flex-direction: row;
  }
`;

const ButtonBack = styled(AvocadoButton)`
  background: ${({ theme }) => theme.colors.grayscale.gray6};
  color: ${({ theme }) => theme.colors.grayscale.gray1};
  font-weight: 500;
  margin-top: 12px;
  min-width: 100%;

  @media ${MEDIA_QUERIES.md} {
    margin-top: 0px;
    min-width: 130px;
  }
`;

const ButtonConfirm = styled(AvocadoButton)`
  font-weight: 500;
  min-width: 100%;

  @media ${MEDIA_QUERIES.md} {
    min-width: 240px;
  }
`;

const Input = styled(InputText)`
  color: ${({ theme }) => theme.colors.grayscale.gray1};
  margin-top: 4px;
  padding-right: 50px;
  width: 100%;
`;

export {
  Container,
  Title,
  Description,
  Caption,
  ButtonBack,
  ButtonsContainer,
  ButtonConfirm,
  LoginInfo,
  Label,
  Input,
};
