const CHECKOUT_QUERY = `
query checkout($token: UUID!) {
 checkout(token: $token) {
           id
           discountAmount{
               amount
           }
           voucher
           shippingPrice{
               gross{
                   amount
               }
           }
           subtotalPrice{
               gross{
                   amount
               }
               net{
                   amount
               }
           }
           totalPrice{
               gross{
                   amount
                   currency
               }
                               net{
                   amount
               }
           }
           warehouse
       }
   }
`;

const CHECKOUT_POSTAL_CODE_UPDATE = `
mutation checkoutPostalCodeUpdate($checkoutId: ID!, $postalCode: String!){
    checkoutPostalCodeUpdate(checkoutId: $checkoutId, postalCode: $postalCode ){
      checkout{
        id
        created
      }
    }
  }
`;

const UNAVAILABLE_CHECKOUT_LINES = `
      query unavailableCheckoutLines($postalCode: String) {
        unavailableCheckoutLines(first:10, postalCode: $postalCode) {
          edges {
            node {
              variant {
                sku,
                id,
                name,
                product {
                  id,
                  name,
                  thumbnailUrl,
                  url,
                  pricing {
                    priceRange {
                      stop {
                        gross {
                          amount
                        }
                      }
                    }
                  },
                  category {
                    id
                  }
                }
              }
              quantity
            }
          }
        }
      }
`;

export {
  CHECKOUT_QUERY,
  CHECKOUT_POSTAL_CODE_UPDATE,
  UNAVAILABLE_CHECKOUT_LINES,
};
