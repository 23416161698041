import styled from "styled-components";
import {
  Typography,
  Button as AvocadoButton,
  Icon as AvocadoIcon,
} from "@justomx/avocado-ui";

const Container = styled.section`
  padding: 20px;
  padding-top: 0px;
  width: 100%;
`;

const Title = styled(Typography)`
  font-weight: 700;
  margin: 0px;
  margin-bottom: 20px;
`;

const LoginInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-weight: 400;
  margin: 0px;
  margin-top: 38px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.green50};
    font-weight: 500;
    text-decoration: none;
  }
`;

const Button = styled(AvocadoButton)`
  font-weight: 500;
  margin-top: 34px;
`;

const Icon = styled(AvocadoIcon).attrs(({ theme }) => ({
  fill: theme.colors.brand.primary,
}))`
  margin-right: 10px;
`;

const NoCoverageButton = styled(AvocadoButton)`
  background: ${({ theme }) => theme.colors.grayscale.gray6};
  color: ${({ theme }) => theme.colors.text.link};
  font-weight: 500;
  margin-top: 34px;
`;

export { Container, Title, Button, LoginInfo, Icon, NoCoverageButton };
