import React, { forwardRef, useImperativeHandle, useState } from "react";
import Cookies from "js-cookie";
import { i18next } from "@/translations";
import PropTypes from "prop-types";

import { FullModalBody } from "@/components/FullModal";

import {
  StyledTitle,
  Warning,
  Buttons,
  Action,
  StyledContainer,
} from "./NoStockModal.styles";
import { CHECKOUT_QUERY } from "./NoStockModal.queries";
import * as graphqlClient from "@/utils/graphql-client";
import {
  cartUpdateTracking,
  getUnavailableCheckoutLines,
  updatePostalCodeCheckout,
} from "./NoStockModal.utils";
import { ModalBody } from "./components/ModalBody";

const NoStockModal = forwardRef(({ onConfirm, onCancel }, ref) => {
  const [show, setShow] = useState(false);
  const [lines, setLines] = useState([]);
  const [addressId, setAddressId] = useState([]);
  const [postalCode, setPostalCode] = useState([]);

  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false),
    updatePostalCodeCheckout: updatePostalCodeCheckout,
    hasUnavailableLines: checkUnavailableLines,
  }));

  const checkUnavailableLines = async (postalCode, addressId) => {
    const lines = await getUnavailableCheckoutLines(postalCode);
    if (lines.length) {
      setAddressId(addressId);
      setPostalCode(postalCode);
      setLines(lines);
      return true;
    }

    return false;
  };

  const onConfirmUpdate = async (lines, token) => {
    try {
      const variables = { token };

      const checkout = await graphqlClient.fetch(
        CHECKOUT_QUERY,
        variables,
        "checkout"
      );

      cartUpdateTracking(checkout, lines, token);
    } catch (errors) {
      return [];
    }
  };

  const onCancelClicked = () => {
    setShow(false);
    onCancel();
  };

  const onConfirmClicked = () => {
    onConfirmUpdate(lines, Cookies.get("checkout"));
    onConfirm(postalCode, addressId);

    setShow(false);
  };

  return (
    <FullModalBody closable={false} show={show}>
      <StyledContainer>
        <StyledTitle>
          {i18next.t(
            "noStockModal.updated_delivery_zone",
            "You updated the delivery zone"
          )}
        </StyledTitle>
        <ModalBody lines={lines} />
        <Warning>
          {i18next.t(
            "noStockModal.the_availability_of_your_products",
            "The availability of your products will be affected."
          )}
        </Warning>
        <Warning>
          {i18next.t(
            "noStockModal.are_you_sure",
            "Are you sure you want to continue?"
          )}
        </Warning>
        <Buttons>
          <Action onClick={onCancelClicked} outline>
            {i18next.t("noStockModal.cancel", "Cancel")}
          </Action>
          <Action onClick={onConfirmClicked}>
            {i18next.t("noStockModal.confirm", "Confirm")}
          </Action>
        </Buttons>
      </StyledContainer>
    </FullModalBody>
  );
});

NoStockModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

NoStockModal.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
};

NoStockModal.displayName = "NoStockModal";

export { NoStockModal };
