import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import {
  FETCH_CONTACT,
  Footer as CommonFooter,
} from "@justomx/common/dist/shared/Footer";
import { useCountryConfig } from "@justomx/common/dist/hooks/useCountryConfig";
import { getFeatures } from "@justomx/common/dist/hooks/useGrowthBook";

function Footer({ as, logo }) {
  const [contact, setContact] = useState({});
  const { countryConfig } = useCountryConfig();
  const { country } = countryConfig;

  const isBR = country === "BR";
  const [showImage, setShowImage] = useState(isBR);

  const [getData] = useLazyQuery(FETCH_CONTACT, {
    onCompleted: ({ shop }) => {
      setContact(shop);
    },
  });

  async function getFlagEvaluationImage() {
    const { enable_customer_evaluation_image } = await getFeatures({});
    if (!enable_customer_evaluation_image) {
      setShowImage(false);
    }
  }

  useEffect(() => {
    if (isBR) {
      getFlagEvaluationImage();
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <CommonFooter
      as={as}
      contact={contact}
      logo={logo}
      enableEvaluationImage={showImage}
    />
  );
}

Footer.propTypes = {
  as: PropTypes.string,
  logo: PropTypes.object,
};

export { Footer };
