import styled from "styled-components";
import { Typography } from "@justomx/avocado-ui";

const Container = styled.div`
  background: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  padding: 8px 0px 16px;
  position: absolute;
  width: 100%;
  overflow: scroll;
  max-height: 132px;
`;

const District = styled(Typography)`
  color: ${({ theme }) => theme.colors.grayscale.gray1};
  cursor: pointer;
  margin: 0px;
  padding: 10px;
  :hover {
    text-decoration: none;
    background: ${({ theme }) => theme.colors.grayscale.gray5};
  }
`;

export { Container, District };
