import analytics from "@justomx/common/dist/libs/analytics";

function logout(redirect = "/account/logout/") {
  analytics.reset();
  localStorage.clear();
  sessionStorage.clear();
  location.replace(redirect);
}

export { logout };
