import { i18next } from "@/translations";

const WHERE_WILL_WE_DELIVER = i18next.t(
  "districtModal.whereWillWeDeliver",
  "Where will we deliver your order?"
);

const YOUR_DISTRIT = i18next.t(
  "districtModal.yourDistrict",
  "Write your district"
);

const ALREADY_HAVE_AN_ACCOUNT = i18next.t(
  "districtModal.alreadyHaveAnAccount",
  "Already have an account?"
);

const LOG_IN = i18next.t("districtModal.logIn", "Log in");

const CONFIRM = i18next.t("districtModal.consfirm", "Confirm");

const NOTIFY = i18next.t(
  "districtModal.notify",
  "Notify when there is coverage"
);

export {
  WHERE_WILL_WE_DELIVER,
  YOUR_DISTRIT,
  ALREADY_HAVE_AN_ACCOUNT,
  LOG_IN,
  CONFIRM,
  NOTIFY,
};
