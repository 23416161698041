import { countriesConfig } from "@/config";

function useCountryConfig(countryCode = process.env.REACT_APP_COUNTRY_CODE) {
  /*
    TODO: Add useMemo, was removed for used legacy code
  */

  const countryConfig = countriesConfig[countryCode];

  const getProperty = (propName) => {
    if (propName in countryConfig) {
      return countryConfig[propName];
    }
    return undefined;
  };

  const getMaskProperty = (propName) => {
    const maskObj = getProperty("masks");

    if (propName in maskObj) {
      return maskObj[propName];
    }

    return undefined;
  };

  return { countryConfig, getProperty, getMaskProperty };
}

export { useCountryConfig };
