const SIZES = {
  mobileM: "480px",
  mobileL: "560px",
  tablet: "768px",
  desktop: "1024px",
  desktopM: "1130px",
};

const mediaQueries = {
  mobileL: `(max-width: ${SIZES.mobileL})`,
  mobileM: `(max-width: ${SIZES.mobileM})`,
  tablet: `(max-width: ${SIZES.tablet})`,
  desktop: `(max-width: ${SIZES.desktop})`,
  desktopM: `(max-width: ${SIZES.desktopM})`,
};

export { mediaQueries };
