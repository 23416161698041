import { gql } from "@apollo/client";

const GET_DISTRICTS = gql`
  query getDistrict($first: Int, $name: String) {
    district(first: $first, name: $name) {
      pageInfo {
        __typename
        hasNextPage
        endCursor
      }
      edges {
        node {
          name
          fullCoverage
          zipCode
          zones
        }
      }
    }
  }
`;

export { GET_DISTRICTS };
