import { differenceInHours } from "date-fns";

function sanitizeIcon(icon_logo) {
  const sanitize = {
    url: icon_logo?.data.attributes.url || "",
    alt: icon_logo?.data.attributes.alternativeText || "",
  };

  return sanitize;
}

function sanitizeCmsHeader({ CMSheader }) {
  const { header_logo_redirect, header_name, header_logo } =
    CMSheader.data.attributes;

  const cmsHeader = {
    logo: {
      header_logo_redirect,
      header_name,
      header_logo: sanitizeIcon(header_logo),
    },
  };
  return cmsHeader;
}

function setLogoResponseInCache(target, data) {
  localStorage.setItem(`logo-${target}-date`, new Date());
  localStorage.setItem(`logo-${target}-data`, JSON.stringify(data));
}

function isValidTimeToGetData(target) {
  if (localStorage.getItem(`logo-${target}-date`)) {
    const date = new Date(localStorage.getItem(`logo-${target}-date`));
    const now = new Date();

    const diff = differenceInHours(now, date);

    if (diff < 1) {
      return true;
    }
  }
}

function isDataOnCache(target) {
  if (localStorage.getItem(`logo-${target}-data`)) {
    if (isValidTimeToGetData(target)) {
      return JSON.parse(localStorage.getItem(`logo-${target}-data`));
    }
  }
}

export { sanitizeCmsHeader, isDataOnCache, setLogoResponseInCache };
