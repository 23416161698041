import styled from "styled-components";

const ProductInfo = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const ProductDescription = styled.div`
  width: 100%;
  display: block;
  margin-left: 5px;
  h1 {
    width: 100%;
    min-height: 19px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
  }
`;

const ProductDetails = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

const ProductVariant = styled.p`
  width: 50%;
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.74px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
`;

const ProductQuantity = styled.p`
  width: 50%;
  height: 16px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.74px;
  text-align: right;
  color: rgba(0, 0, 0, 0.85);
  text-align: right;
`;

const Products = styled.div`
  width: 100%;
  padding: 0px;
  margin-bottom: 50px;
`;

const Header = styled.div`
  width: 90%;
  display: flex;
  margin-left: 61px;
`;

const HeaderProduct = styled.p`
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.74px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  margin: auto;
`;

const HeaderQuantity = styled.p`
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.74px;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  margin: auto;
  margin-right: 20px;
`;

const ProductImage = styled.img`
  max-width: 56px;
  max-height: 56px;
`;

export {
  Header,
  HeaderProduct,
  HeaderQuantity,
  ProductDescription,
  ProductDetails,
  ProductImage,
  ProductInfo,
  ProductQuantity,
  Products,
  ProductVariant,
};
