import styled from "styled-components";
import { Icon, InputText, Typography } from "@justomx/avocado-ui";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled(InputText)`
  color: ${({ theme }) => theme.colors.grayscale.gray1};
  padding-right: 50px;
  width: 100%;
`;

const IconContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: absolute;
  right: 12px;
  top: 0px;
  width: min-content;
`;

const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.colors.grayscale.gray3,
}))``;

const NoCoverage = styled(Typography)`
  color: ${({ theme }) => theme.colors.red50};
  font-size: 12px;
  font-weight: 400;
  margin: 4px 0px 18px 0px;
  position: absolute;
`;

const Label = styled(Typography)`
  color: ${({ theme, error }) =>
    error ? theme.colors.red50 : theme.colors.grayscale.gray3};
  display: ${({ show }) => (show ? "block" : "none")};
  font-size: 12px;
`;

const PartialCoverage = styled.section`
  display: ${({ show }) => (show ? "block" : "none")};
  margin-bottom: 20px;
  width: 100%;
`;

const TitlePartialCoverage = styled(Typography)`
  font-size: 16px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
`;

const ZonesPartialCoverage = styled(Typography)`
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
`;

export {
  Container,
  Input,
  IconContainer,
  CloseIcon,
  NoCoverage,
  Label,
  PartialCoverage,
  TitlePartialCoverage,
  ZonesPartialCoverage,
};
