import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";

import { GET_DISTRICTS } from "./Suggestions.queries";

import * as Styled from "./Suggestions.styles";
import { DistrictName } from "./DistrictName";

function Suggestions({ district, onClickDistrict, districtNoCoverage }) {
  const [suggestions, setSuggestions] = useState([]);

  const [getDistricts] = useLazyQuery(GET_DISTRICTS, {
    onCompleted: ({ district }) => {
      const { edges } = district;

      districtNoCoverage(!edges.length);
      setSuggestions(edges);
    },
  });

  useEffect(() => {
    if (district) {
      getDistricts({
        variables: {
          name: district,
          first: 10,
        },
      });
    }
  }, [district]);

  if (!district) {
    return <></>;
  }

  return (
    <Styled.Container show={suggestions.length}>
      {suggestions.map(({ node }, key) => (
        <DistrictName onClickDistrict={onClickDistrict} node={node} key={key} />
      ))}
    </Styled.Container>
  );
}

Suggestions.propTypes = {
  district: PropTypes.string,
  onClickDistrict: PropTypes.func,
  districtNoCoverage: PropTypes.func,
};

export { Suggestions };
