import * as Yup from "yup";
import * as TR from "./NoCoverageModal.translations";

function getInitialValues() {
  const initialValues = {
    email: "",
  };

  return initialValues;
}

function getSchema() {
  const Schema = Yup.object().shape({
    email: Yup.string().required(TR.EMAIL_REQUIRED).email(TR.INVALID_FORMAT),
  });

  return Schema;
}

export { getInitialValues, getSchema };
