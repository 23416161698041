import React from "react";
import { render } from "react-dom";
import { Header } from "./Header";
import { GlobalStyles, ThemeProvider, THEMES } from "@justomx/avocado-ui";
import { getCmsHeader } from "../Layout/LayoutMain";

const container = document.getElementById("header");

async function fetchCmsLogos() {
  const cmsHeader = await getCmsHeader();

  const { logo: logoHeader } = cmsHeader;

  if (container) {
    render(
      <ThemeProvider theme={THEMES.light}>
        <GlobalStyles />
        <Header as="header" logo={logoHeader} />
      </ThemeProvider>,
      container
    );
  }
}

fetchCmsLogos();
