import styled from "styled-components";
import { Heading, Typography, Icon } from "@justomx/avocado-ui";

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 6;
`;

const ErrorIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.colors.status.error,
}))``;

const SuccessIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.colors.brand.primaryHard,
}))``;

const DefaultIcon = styled(Icon).attrs(({ theme }) => ({
  fill: theme.colors.grayscale.white,
}))``;

const ImageSucces = styled.img`
  width: 45px;
  max-height: 45px;
  object-fit: contain;
  margin-right: 4px;
`;

const NotificationTitle = styled.p`
  font-weight: bold;
  color: ${({ theme, textColor }) => textColor && theme.colors.brand.primary};
  margin: 0;
`;

const NotificationContent = styled.p`
  margin: 0;
  color: ${({ theme, textColor }) => textColor && theme.colors.brand.primary};
`;

const NotificationTitleOffer = styled(Heading)`
  color: ${({ theme }) => theme.colors.brand.primary};
  text-align: center;
  margin-bottom: 10px;
`;

const NotificationContentOffer = styled(Typography)`
  text-align: center;
`;

export {
  IconContainer,
  ImageSucces,
  MainContent,
  NotificationContainer,
  NotificationContent,
  NotificationContentOffer,
  NotificationTitle,
  NotificationTitleOffer,
  DefaultIcon,
  ErrorIcon,
  SuccessIcon,
};
