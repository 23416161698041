import React from "react";
import PropTypes from "prop-types";

import { ThemeProvider, THEMES } from "@justomx/avocado-ui";
import { NotificationProvider } from "@/contexts/NotificationContext";

function BaseLayout({ children }) {
  return (
    <ThemeProvider theme={THEMES.light}>
      <NotificationProvider>{children}</NotificationProvider>
    </ThemeProvider>
  );
}

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export { BaseLayout };
