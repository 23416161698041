import { toGlobalId, fromGlobalId } from "@/utils/graphql-client";
import { getCookie, setCookie, deleteCookie } from "@/hooks/useCookies";

function getCheckoutId() {
  const checkout = getCookie("checkout");

  return toGlobalId("Checkout", checkout || "");
}

function updateCheckoutCookie(checkout) {
  const cookieCheckout = getCheckoutId();
  const checkoutId = fromGlobalId(checkout.id).id;

  if (cookieCheckout !== checkoutId) {
    setCookie("checkout", checkoutId);
  }
}

function updateSiteCookies(postalCode, addressId) {
  setCookie("postal_code", postalCode, 365);
  deleteCookie("has_default_postal_code");

  if (addressId != null) {
    setCookie("shipping_address_id", addressId, 365);
  } else {
    deleteCookie("shipping_address_id");
  }
}

export { getCheckoutId, updateCheckoutCookie, updateSiteCookies };
