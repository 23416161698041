import styled from "styled-components";
import { MEDIA_QUERIES } from "@justomx/avocado-ui";

const Container = styled.header`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 24%);
  margin-bottom: 16px;
  top: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: "sticky";
  padding: 16px;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Link = styled.a``;

const SiteLogo = styled.img`
  width: 87px;
  height: 24px;

  @media ${MEDIA_QUERIES.lg} {
    width: 97px;
    height: 30px;
  }
`;

const LogoFresh = styled.img`
  width: 160px;
  height: 28px;

  @media ${MEDIA_QUERIES.lg} {
    width: 180px;
    height: 35px;
  }
`;

export { Container, LogoContainer, Link, SiteLogo, LogoFresh };
