import i18next from "i18next";
import es_MX from "./locales/es-MX.json";
import es_PE from "./locales/es-PE.json";
import pt_BR from "./locales/pt-BR.json";

const COUNTRY_LOCALE = process.env.REACT_APP_COUNTRY_LOCALE;

i18next.init({
  lng: COUNTRY_LOCALE,
  debug: false,
  resources: {
    // TODO: USE DYNAMIC Namespace, to avoid use all translations
    "pt-BR": {
      translation: pt_BR,
    },
    "es-MX": {
      translation: es_MX,
    },
    "es-PE": {
      translation: es_PE,
    },
  },
});

export { i18next };
