const API_URL = process.env.REACT_APP_API_URL;

async function graphqlFetch(query, variables = {}, operationName = null) {
  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables,
      operationName,
    }),
    credentials: "include",
  });

  const { errors, data } = await response.json();

  if (errors && errors.length) {
    throw errors;
  }

  return data;
}

function fromGlobalId(globalId) {
  const [type, id] = atob(globalId).split(":");
  return { type: type, id: id };
}

function toGlobalId(type, id) {
  const stringConcatenated = type + ":" + id.toString();
  return btoa(stringConcatenated);
}

export {
  graphqlFetch as fetch,
  fromGlobalId as fromGlobalId,
  toGlobalId as toGlobalId,
};
