import { ApolloClient, InMemoryCache } from "@apollo/client";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const cmsClient = new ApolloClient({
  uri: process.env.REACT_APP_FLY_CMS_URL,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export { cmsClient };
