import React from "react";

import * as Styled from "./Loading.styles";
import carrot from "./images/carrot.svg";
import apple from "./images/apple.svg";
import banana from "./images/banana.svg";
import grapes from "./images/grapes.svg";
import orange from "./images/orange.svg";
import strawberry from "./images/strawberry.svg";

function Loading() {
  return (
    <Styled.Container>
      <Styled.Image src={carrot} alt="carrot" />
      <Styled.Image src={apple} alt="apple" />
      <Styled.Image src={banana} alt="banana" />
      <Styled.Image src={grapes} alt="grapes" />
      <Styled.Image src={orange} alt="orange" />
      <Styled.Image src={strawberry} alt="strawberry" />
    </Styled.Container>
  );
}

export { Loading };
