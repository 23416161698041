import { cmsClient } from "@justomx/common/dist/graphql/clients";

import { GET_CMS_FOOTER, GET_CMS_HEADER } from "./LayoutMain.queries";
import { sanitizeCmsFooter } from "@justomx/common/dist/shared/Footer";
import {
  sanitizeCmsHeader,
  setLogoResponseInCache,
  isDataOnCache,
} from "./LayoutMain.utils";

const getCmsFooter = async () => {
  if (isDataOnCache("footer")) {
    return sanitizeCmsFooter(isDataOnCache("footer"));
  }

  const { data } = await cmsClient.query({
    query: GET_CMS_FOOTER,
  });

  setLogoResponseInCache("footer", data);

  return sanitizeCmsFooter(data);
};

const getCmsHeader = async () => {
  if (isDataOnCache("header")) {
    return sanitizeCmsHeader(isDataOnCache("header"));
  }

  const { data } = await cmsClient.query({
    query: GET_CMS_HEADER,
  });

  setLogoResponseInCache("header", data);

  return sanitizeCmsHeader(data);
};

const getCmsProps = async () => {
  const { logo: logoCmsHeader } = await getCmsHeader();
  const { logo: logoCmsFooter } = await getCmsFooter();

  return { logoCmsHeader, logoCmsFooter };
};

export { getCmsProps, getCmsFooter, getCmsHeader };
