import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";

import { ZipCodeModalProvider } from "@justomx/common/dist/contexts/ZipCodeModalContext";
import { getCmsProps } from "./LayoutMain.loaders";
import { BaseLayout } from "@justomx/common/dist/shared/BaseLayout";
import { GlobalStyles } from "@justomx/avocado-ui";
import { ApolloProvider } from "@apollo/client";
import { coreClient } from "@/graphql/clients";

import { Header } from "@/shared/Header";
import { Footer } from "@/shared/Footer";

import { LegacyNotifications } from "./components/LegacyNotifications";
import { RootContainer } from "./LayoutMain.styles";

import "react-toastify/dist/ReactToastify.min.css";

function LayoutMain({ children }) {
  const [logos, setLogos] = useState({});

  async function fetchCmsLogos() {
    const cmsLogos = await getCmsProps();

    const { logoCmsHeader, logoCmsFooter } = cmsLogos;

    setLogos({ logoHeader: logoCmsHeader, logoFooter: logoCmsFooter });
  }

  useLayoutEffect(() => {
    fetchCmsLogos();
  }, []);

  if (Object.keys(logos).length === 0) {
    return <></>;
  }

  const { logoHeader, logoFooter } = logos;

  return (
    <ApolloProvider client={coreClient}>
      <BaseLayout>
        <GlobalStyles />
        <ZipCodeModalProvider>
          <LegacyNotifications />
          <RootContainer>
            <Header as="header" logo={logoHeader} />
            {children}
            <Footer as="footer" logo={logoFooter} />
          </RootContainer>
        </ZipCodeModalProvider>
      </BaseLayout>
    </ApolloProvider>
  );
}

LayoutMain.propTypes = {
  children: PropTypes.node,
};

export { LayoutMain };
